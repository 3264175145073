import React from 'react';
import { useTheme } from 'styled-components';

const IconArrowLeft: React.FC<React.SVGProps<SVGSVGElement>> = props => {
  const theme = useTheme();
  const color1 = theme.icon.color1;
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path
        d="m4.33,13.96l15.33,6.47v-2.85l-12.03-5.14c-.33-.21-.26-.63.02-.76l12-5.26v-2.84l-15.33,6.59v3.79Z"
        fill={color1}
      />
    </svg>
  );
};

export default IconArrowLeft;

import React, { useEffect } from 'react';
import { Card, Typography } from '@/components/atoms';
import Link from 'next/link';
import { ARTISANS_PATH } from '@/lib/constants';
import styled from 'styled-components';
import Image from 'next/image';
import useElementSize from '@/hooks/useElementSize';
import { IArtisanCardCmp } from './IArtisanCardCmp';

const CardContainer = styled(Card)`
  width: 100%;
  height: 100%;
  max-width: 276px;

  img {
    display: block;
    width: 100%;
  }
  line-height: 0;
`;

const InfoContainer = styled.div`
  display: flex;
  justify-content: left;
  align-items: left;
  flex-direction: column;
  padding: 8px 0px;
  text-transform: uppercase;
  h2 {
    font-size: 16px;
  }
`;

const CustomTypo = styled(Typography)`
  line-height: 22px;
`;

const ArtisanCard: React.FC<IArtisanCardCmp> = props => {
  const [imgRef, { height }] = useElementSize();

  const { setArrowTopPos, name, slug, image } = props;
  const featImgSrc = image ?? '/asset/placeholders/artisan-card.jpg';

  const nameOfTheArtisan = name || <div>&nbsp;</div>;

  useEffect(() => {
    if (setArrowTopPos && height) {
      setArrowTopPos(height);
    }
  }, [height]);

  return (
    <Link href={`/${ARTISANS_PATH}/${slug}`} passHref>
      <a
        style={{
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          maxWidth: '276px',
        }}>
        <CardContainer>
          <div ref={imgRef}>
            <Image
              src={featImgSrc}
              alt={name}
              width={276}
              height={368}
              layout={'responsive'}
              unoptimized={true}
            />
          </div>

          <InfoContainer>
            <CustomTypo component="h2" datatype="body_1" className="smalltitle">
              {nameOfTheArtisan}
            </CustomTypo>
          </InfoContainer>
        </CardContainer>
      </a>
    </Link>
  );
};

export default ArtisanCard;

import { useEffect } from 'react';
import { ICardCustomCmp } from './ICardCustomCmp';
import styled from 'styled-components';
import {
  Card,
  ImageWithRatio,
  LinkHandler,
  Typography,
} from '@/components/atoms';
import useElementSize from '@/hooks/useElementSize';

const Container = styled.div<IContainer>`
  width: 100%;
  max-width: 420px;
  min-width: 200px;
  text-align: ${props => props.align};
  a {
    text-decoration: none;
    display: block;
  }

  &:last-child {
    margin-bottom: 0px;
  }
  line-height: 0;
`;

const CustomCard = styled(Card)`
  display: block;
  background-color: transparent;
  & img {
    border-radius: ${props => props.theme.common.borderRadius};
  }
`;

const InfoContainer = styled.div`
  margin-top: 8px;
`;

const Heading = styled.div``;

const CustomTypo = styled(Typography)<ICustomTypo>`
  line-height: ${props => (props.template !== 'card_small' ? '32px' : '24px')};
`;

const Description = styled.div`
  p {
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
  }
`;

const ImageDesktop = styled.div`
  display: none;
  ${props => props.theme.breakpoints.up('md')} {
    display: block;
  }
`;
const ImageMobile = styled.div`
  display: block;
  ${props => props.theme.breakpoints.up('md')} {
    display: none;
  }
`;

interface IContainer {
  align: string;
}

interface ICustomTypo {
  template: string | undefined;
}

const CardCustom: React.FC<ICardCustomCmp> = props => {
  const [imgRef, { height }] = useElementSize();
  const {
    headingText,
    url,
    target,
    mediaMobile,
    media,
    altTag,
    description,
    template,
    setArrowTopPos,
  } = props;

  useEffect(() => {
    if (setArrowTopPos && height) {
      setArrowTopPos(height);
    }
  }, [height]);

  const headingType = template === 'card_small' ? 'subtitle_2' : 'subtitle_1';
  const descriptionType = template === 'card_small' ? 'caption' : 'body_1';

  const content = (
    <CustomCard>
      <div ref={imgRef}>
        {media &&
          media.fields &&
          media.fields.file &&
          media.fields.file.details &&
          media.fields.file.details.image && (
            <ImageDesktop>
              <ImageWithRatio
                ratio={{
                  width: media?.fields?.file?.details?.image?.width,
                  height: media?.fields?.file?.details?.image?.height,
                }}
                media={media}
                alt={altTag}
              />
            </ImageDesktop>
          )}

        {mediaMobile &&
          mediaMobile.fields &&
          mediaMobile.fields.file &&
          mediaMobile.fields.file.details &&
          mediaMobile.fields.file.details.image && (
            <ImageMobile>
              <ImageWithRatio
                ratio={{
                  width: mediaMobile?.fields?.file?.details.image.width,
                  height: mediaMobile?.fields?.file?.details.image.height,
                }}
                media={mediaMobile}
                alt={altTag}
              />
            </ImageMobile>
          )}
      </div>
      {headingText || description ? (
        <InfoContainer>
          {headingText && (
            <Heading>
              <CustomTypo
                dangerouslySetInnerHTML={{
                  __html: headingText,
                }}
                datatype={headingType}
                className="smalltitle"
                template={template}
              />
            </Heading>
          )}
          {description && (
            <Description>
              <Typography datatype={descriptionType}>{description}</Typography>
            </Description>
          )}
        </InfoContainer>
      ) : null}
    </CustomCard>
  );

  return (
    <Container align={description ? 'left' : 'center'}>
      {url ? (
        <LinkHandler href={url} passHref>
          <a target={target}>{content}</a>
        </LinkHandler>
      ) : (
        content
      )}
    </Container>
  );
};

export default CardCustom;

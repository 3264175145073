import React from 'react';
import { useTheme } from 'styled-components';

const IconArrowRight: React.FC<React.SVGProps<SVGSVGElement>> = props => {
  const theme = useTheme();
  const color1 = theme.icon.color1;
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path
        d="m19.67,10.04L4.33,3.57v2.85l12.03,5.14c.33.21.26.63-.02.76l-12,5.26v2.84l15.33-6.59v-3.79Z"
        fill={color1}
      />
    </svg>
  );
};

export default IconArrowRight;

import { ArtisanCard, CardCustom } from '@/components/molecules';
import { IBrandCard } from '@/components/organisms/ArtisanWithProducts/BrandViewModel';
import { IImageWithTextData } from '@/components/organisms/ImageWithTextBlock/IImageWithTextCmp';
import useBreakpoint from '@/hooks/useBreakpoint';
import { Product } from '@/lib/models/types/product';
import { ProductCardCustomization } from '@/types/products';
import { useRouter } from 'next/router';
import React, { useCallback, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import SwiperCore, {
  Autoplay,
  Navigation,
  Scrollbar,
  Swiper as SwiperType,
} from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';
import ProductCardV2 from '../ProductCard';
import { IconArrowRight, IconArrowLeft } from '@/components/atoms';
import { debounce } from '@/utils/index';

const OuterContainer = styled.div<Partial<ICardSlider>>`
  background-color: ${props => props.backgroundColor};
  ${props => props.backgroundColor && 'padding: 24px 0'};
`;

const Container = styled.div<IContainer>`
  max-width: 100%;
  margin: 0 auto;
  margin-left: ${props =>
    props.cardsLength === 1 || !props.withMargins ? '0' : '0px'};
  display: flex;
  position: relative;
  ${props => props.theme.breakpoints.up('lg')} {
    padding-left: 0px;
  }
  ${props =>
    props.iscentered &&
    props.withMargins &&
    css`
      .swiper-slide {
        margin-right: 24px;
        &:last-child {
          margin-right: 0px;
        }
        ${props => props.theme.breakpoints.up('lg')} {
          margin-right: 32px;
          &:last-child {
            margin-right: 0px;
          }
        }
      }
    `}
  .swiper-container {
    padding: ${props => (props.withScrollBar ? '2px 2px 48px 2px' : '2px')};
    margin-left: 0;
    margin-right: 0;
  }
  .swiper-slide {
    display: flex;
    width: 240px !important;
    margin-right: 24px;
    ${props =>
      props.template === 'carousel_small' &&
      css`
        width: 192px !important;
        margin-right: 24px;
      `}
    ${props =>
      props.template === 'carousel' &&
      props.typeCard !== 'custom' &&
      css`
        width: 224px !important;
        margin-right: 16px;
      `}
    ${props =>
      props.template === 'carousel_small' &&
      props.typeCard !== 'custom' &&
      css`
        width: 192px !important;
        margin-right: 16px;
      `}

    ${props => props.theme.breakpoints.up('sm')} {
      width: calc((1280px - 24px * 3) / 4) !important;
      margin-right: 24px;
      ${props =>
        props.template === 'carousel_small' &&
        css`
          width: calc((1280px - 24px * 4) / 5) !important;
          margin-right: 24px;
        `}

      ${props =>
        props.template === 'carousel' &&
        props.typeCard !== 'custom' &&
        css`
          width: 248px !important;
          margin-right: 24px;
        `}
      ${props =>
        props.template === 'carousel_small' &&
        props.typeCard !== 'custom' &&
        css`
          width: 224px !important;
          margin-right: 16px;
        `}
    }
    ${props => props.theme.breakpoints.up('laptop')} {
      width: calc((1520px - 24px * 3) / 4) !important;
      margin-right: 24px;

      ${props =>
        props.template === 'carousel_small' &&
        css`
          width: calc((1520px - 24px * 4) / 5) !important;
          margin-right: 24px;
        `}

      ${props =>
        props.template === 'carousel' &&
        props.typeCard !== 'custom' &&
        css`
          width: 256px !important;
          margin-right: 24px;
        `}
      ${props =>
        props.template === 'carousel_small' &&
        props.typeCard !== 'custom' &&
        css`
          width: 224px !important;
          margin-right: 24px;
        `}
    }
  }
  .swiper-scrollbar {
    display: ${props => (props.withScrollBar ? 'block' : 'none !important')};
    ${props => props.theme.breakpoints.up(1200)} {
      display: ${props =>
        props.withScrollBar && props.cardsLength === 4
          ? 'none !important'
          : 'block'};
    }
    border-radius: 0px;
    background: ${props => props.theme.cardSlider.backgroundScrollbar};
  }
  .swiper-scrollbar-drag {
    background: ${props => props.theme.cardSlider.colorScrollbar};
    border-radius: 0px;
  }
  .nav {
    position: absolute;
    cursor: pointer;
    top: ${props => props.navTop}px; // 50%;
    z-index: 999999;
    padding-top: 32px; // to expande arrow clickable area
    padding-bottom: 32px; // to expande arrow clickable area
  }
  .navPrev {
    left: 0;
    padding-right: 32px; // to expande arrow clickable area
  }
  .navNext {
    right: 0;
    padding-left: 32px; // to expande arrow clickable area
  }
`;

const ArrowWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${props => props.theme.paletteDefault.white};
  height: 32px;
`;

interface IContainer {
  iscentered?: boolean;
  withScrollBar?: boolean;
  withMargins?: boolean;
  template?: string;
  cardsLength?: number;
  navTop: number | null;
  typeCard?: string;
}

export interface CustomCard {
  typeCard: 'custom';
  cards: IImageWithTextData[];
}

export interface ProductCard {
  typeCard: 'product';
  cards: Product[];
}

export interface BrandCard {
  typeCard: 'artisan';
  cards: IBrandCard[];
}

type ICardSlider = {
  withScrollBar?: boolean;
  withArrows?: boolean;
  withMargins?: boolean;
  template?: string;
  currency?: string;
  type?: string;
  customization?: ProductCardCustomization;
  mode?: string;
  backgroundColor?: string;
} & (CustomCard | ProductCard | BrandCard);

SwiperCore.use([Navigation, Autoplay, Scrollbar]);

const CardSlider: React.FC<ICardSlider> = props => {
  const {
    cards,
    withScrollBar,
    withArrows = true,
    withMargins = true,
    template,
    currency,
    type,
    customization,
    mode,
    backgroundColor,
  } = props;

  let slidesVisible = template === 'carousel_small' ? 6 : 4;

  const [swiperInstance, setSwiperInstance] = useState<SwiperType | null>(null);

  const [arrows, setArrows] = useState({
    left: false,
    right: false,
  });
  const [cardsState, setCardsState] = useState([]);
  const point = useBreakpoint();
  const [arrowTop, setArrowTop] = useState<number | null>(null);

  const setArrowTopPos = (imgCardHeight: number) => {
    if (imgCardHeight) {
      setArrowTop(imgCardHeight / 2 - 52); // 52 is half the size (in px) of the div containing the arrow
    }
  };

  const router = useRouter();

  const updateArrowsCallback = (swiper: SwiperCore) => {
    const { isBeginning, isEnd } = swiper;
    setArrows({
      left: !isBeginning,
      right: !isEnd,
    });
  };
  const onSlideChange = useCallback(debounce(updateArrowsCallback, 400), []);

  useEffect(() => {
    if (swiperInstance && withArrows) {
      updateArrowsCallback(swiperInstance);
    }
  }, [swiperInstance]);

  useEffect(() => {
    return () => {
      setCardsState([]);
    };
  }, [router.asPath]);

  useEffect(() => {
    if (content) {
      setCardsState(content);
    }
  }, [cards, currency]);

  let iscentered = cards.length < slidesVisible;

  let isWithScrollBar = withScrollBar && cards.length > slidesVisible;

  if (point === 'xs' || point === 'sm' || point === 'md') {
    isWithScrollBar = withScrollBar && cards.length > 1;
    slidesVisible = 2;
  }

  let content: any = null;

  const goNext = () => {
    swiperInstance?.slideNext();
  };

  const goPrev = () => {
    swiperInstance?.slidePrev();
  };

  const scrollbarStyle = {
    maxWidth: '360px',
    width: '80%',
    margin: 'auto',
    height: '8px',
    left: point === 'xs' ? 'calc(50% - 12px)' : '50%',
    transform: 'translateX(-50%)',
    display: isWithScrollBar ? 'block' : 'none !important',
  };

  const getContent = () => {
    if (props.typeCard === 'custom') {
      return props.cards.map((card, index) => {
        return (
          <SwiperSlide key={`${card?.id || 'card'}-${index}`}>
            <CardCustom
              headingText={card.headingText}
              id={card.id}
              media={card?.media}
              mediaMobile={card?.mediaMobile || card?.media}
              target={card.target}
              url={card.url}
              urlLabel={card.urlLabel}
              altTag={card.altTag}
              description={card.description}
              template={template === 'carousel_small' ? 'card_small' : ''}
              setArrowTopPos={index === 0 ? setArrowTopPos : undefined}
            />
          </SwiperSlide>
        );
      });
    }
    if (props.typeCard === 'artisan') {
      return props.cards.map((card, index) => {
        return (
          <SwiperSlide key={`${card?.id || 'card'}-${index}`}>
            <ArtisanCard
              {...card}
              setArrowTopPos={index === 0 ? setArrowTopPos : undefined}
            />
          </SwiperSlide>
        );
      });
    }
    if (props.typeCard === 'product') {
      return props.cards.map((product, index) => {
        if (mode === 'server') {
          return (
            <SwiperSlide key={`${product?.id || 'card'}-${index}`}>
              <ProductCardV2
                product={product}
                type={type}
                customization={customization}
                positionindex={index + 1}
                template={template}
                setArrowTopPos={index === 0 ? setArrowTopPos : undefined}
              />
            </SwiperSlide>
          );
        } else {
          return (
            <SwiperSlide key={`${product?.id || 'card'}-${index}`}>
              <ProductCardV2
                product={product}
                type={type}
                customization={customization}
                positionindex={index + 1}
                template={template}
                setArrowTopPos={index === 0 ? setArrowTopPos : undefined}
              />
            </SwiperSlide>
          );
        }
      });
    }
  };

  content = getContent();

  let swiperContent = null;
  if (mode === 'server') {
    swiperContent = getContent();
  } else {
    if (cardsState?.length > 0) {
      swiperContent = cardsState;
    }
  }

  const isMobile = point === 'xs' || point === 'sm';

  return (
    <OuterContainer backgroundColor={backgroundColor}>
      <Container
        iscentered={iscentered}
        withScrollBar={isWithScrollBar}
        withMargins={withMargins}
        template={template}
        cardsLength={cards.length}
        navTop={arrowTop}
        typeCard={props.typeCard}>
        <Swiper
          touchEventsTarget={'container'}
          grabCursor={true}
          slidesPerView={'auto'}
          freeMode={true}
          scrollbar={{
            draggable: isWithScrollBar,
            el: '.swiper-scrollbar',
            snapOnRelease: false,
            dragSize: 360 / (cards.length / slidesVisible),
            hide: !isWithScrollBar,
          }}
          onInit={swiper => {
            setSwiperInstance(swiper);
          }}
          onResize={onSlideChange}
          onTransitionEnd={onSlideChange}>
          {swiperContent}
          <div className="swiper-scrollbar" style={scrollbarStyle}></div>
        </Swiper>
        {arrowTop && !isMobile && arrows?.left ? (
          <div className="nav navPrev" onClick={goPrev}>
            <ArrowWrapper>
              <IconArrowLeft width={32} height={32} />
            </ArrowWrapper>
          </div>
        ) : null}
        {arrowTop && !isMobile && arrows?.right ? (
          <div className="nav navNext" onClick={goNext}>
            <ArrowWrapper>
              <IconArrowRight width={32} height={32} />
            </ArrowWrapper>
          </div>
        ) : null}
      </Container>
    </OuterContainer>
  );
};

CardSlider.defaultProps = {
  withScrollBar: false,
};

export default CardSlider;
